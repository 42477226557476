import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
Vue.prototype.$axios = axios
// Vue.prototype.$route = router
axios.defaults.baseURL='//api.chongtongkeji.com/v1/blog'

Vue.config.productionTip = false

new Vue({
  router,
  axios,
  render: h => h(App)
}).$mount('#app')
