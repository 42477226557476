import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)



const routes = [
    {
      path: '/',
      name: '404',
      component: () => import('@/views/404.vue')
    },
    {
      path: '/article/:id',
      name: 'article',
      component: () => import('@/views/article.vue'),
      meta:{
        // xxx是自己想要设置的标题名
        title: '隐私策略'
      }
    },
    
    {
      redirect: '/',
      path: '*',
    }
  ]

  const router = new Router({
    mode: 'history',
    routes,
  })
  

export default router
